<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <div v-if="!$route.query._board" class="headline text-start mt-8">장비 등록</div>
            <div v-else class="headline text-start mt-8">장비 상세</div>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1 pb-0">기본정보</v-card-title>
                <v-card-text>
                    <v-row class="mt-3">
                        <v-col class="py-0">
                            <v-text-field v-model="equipment.title" label="장비명" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col class="py-0">
                            <v-select v-model="equipment.work" :items="workItems" item-text="text" item-value="value" label="작업실" persistent-placeholder hide-details></v-select>
                        </v-col>
                        <v-col class="py-0" v-if="equipment.work === DDM_WORK_TYPES.STUDIO.value">
                            <v-select v-model="equipment.center" :items="spaceItems" item-text="text" item-value="value" label="센터" persistent-placeholder hide-details></v-select>
                        </v-col>
                        <v-col class="py-0">
                            <v-checkbox v-model="equipment.isMain" item-text="text" item-value="value" label="메인 노출 여부" persistent-placeholder hide-details></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3">
                        <v-col class="py-0">
                            <v-text-field v-model="equipment.time" label="이용 시간" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col class="py-0">
                            <v-text-field v-model="equipment.location" label="위치" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col class="py-0">
                            <v-text-field v-model="equipment.quantity" label="수량" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1">썸네일</v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="auto" class="py-0">
                            <v-img v-if="equipment.thumb" max-width="96" max-height="96" :src="createObjectURL(equipment.thumb)" contain></v-img>
                            <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                            </v-responsive>
                        </v-col>
                        <v-col class="py-0">
                            <v-file-input v-model="equipment.thumb" label="썸네일 이미지_282×164(px)" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*"></v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-6">
                <v-card-title class="subtitle-1">내용</v-card-title>
                <v-card-text>
                    <naver-smarteditor v-model="equipment.content" id="content" rows="10"></naver-smarteditor>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>

        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { DDM_CENTER_TYPES, DDM_WORK_TYPES } from "@/assets/variables"

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

import { mdiTagPlus } from "@mdi/js";
import urlParser from "js-video-url-parser";
import YoutubeCategoriesView from "@/components/console/boards/youtube-categories-view.vue";
import ReportViewCategories from "@/components/console/boards/report-view-categories.vue";
import FormsViewCategories from "@/components/console/boards/forms-view-categories.vue";

export default {
    components: {
        VDatetimeField,
        DaumPostcode,
        NaverSmarteditor,
        YoutubeCategoriesView,
        ReportViewCategories,
        FormsViewCategories,
    },
    data() {
        return {
            DDM_WORK_TYPES,
            mdiTagPlus,
            ready: false,

            spaceItems: [
                DDM_CENTER_TYPES.YONGSIN,
                DDM_CENTER_TYPES.DAPSIMNI,
            ],

            workItems: [
                DDM_WORK_TYPES.CUTTINGROOM,
                DDM_WORK_TYPES.STUDIO,
            ],
            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            equipment: {
              title: "",
              center: null,
              work: "",
              time: "",
              location: "",
              content: "",
              quantity:"",
              isMain: false,
              thumb: null,
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (this.$route.params._equipment) {
                    let { equipment } = await api.console.equipment.get({ _id: this.$route.params._equipment });
                    if (equipment.thumb) equipment.thumb = await api.getResource(equipment.thumb, true);
                    this.equipment = equipment;
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                if(!this.validation()) return;
                let { thumb, ...equipment } = this.equipment;
                let { put, post } = api.console.equipment;
                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                ({ equipment } = equipment._id ? await put(equipment) : await post(equipment));
                // //////////////////////////////////////////////////////////////
                // // 2. 리소스 저장
                // //////////////////////////////////////////////////////////////
                if (thumb) await api.console.equipment.postThumb(equipment, thumb);

                alert(this.equipment._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },

        validation(){
            if(!this.equipment.work) throw new Error("작업실을 설정해주세요.");

            return true;
        }
    },
    watch: {
        "equipment.work"(){
            if(this.equipment.work === DDM_WORK_TYPES.CUTTINGROOM.value) this.equipment.center = null;
        }
    }
};
</script>

<template>
    <v-dialog v-model="dialog" max-width="900px">
        <template v-slot:activator="{ on }">
            <v-btn bottom color="blue lighten-1" fab fixed right v-on="on">
                <v-icon>mdi-calendar-edit</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <v-col>
                    <v-row>
                        <span class="text-md-h5">신청불가 일자 설정</span>
                    </v-row>
                    <v-row class="mt-2 pa-0">
                        <div style="font-size: 14px">1. 오늘 일자를 기준으로 해당 기간동안 해당 요일 선택을 제한합니다.</div>
                        <div style="font-size: 14px">2. 규칙 설정없이, 달력의 날짜를 선택하여 요일을 지정할 수 있습니다.</div>
                    </v-row>
                </v-col>
            </v-card-title>
            <v-divider/>

            <v-card-text>
                <v-row>
                    <v-col cols="3" class="mt-3 pa-0">
                        <div class="subtitle-2 font-weight-bold">규칙 설정</div>
                    </v-col>
                    <v-divider vertical/>
                    <v-col cols="3" class="mt-3">
                        <v-select v-model="rule.period" :items="periodItems" label="기간"/>
                    </v-col>
                    <v-col cols="3" class="mt-3">
                        <v-select v-model="rule.day" :items="dayItems" label="요일"/>
                    </v-col>
                    <v-col>
                        <v-btn class="mt-6" color="primary" @click="pushLimitDates">적용</v-btn>
                    </v-col>
                </v-row>
                <v-divider/>
                <v-row>
                    <v-col cols="auto">
                        <v-date-picker
                            v-model="limitation.limitDates"
                            multiple
                        />
                    </v-col>
                    <v-divider vertical/>
                    <v-col>
                        <v-chip-group v-model="limitation.limitDates" column>
                            <v-chip v-for="(limitDate, index) in limitation.limitDates" :key="index" close @click:close="limitation.limitDates=limitation.limitDates.filter((date)=> date !== limitDate)">{{ limitDate }}</v-chip>
                        </v-chip-group>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="()=>limitation.limitDates=[]">전부 제거</v-btn>
                <v-btn text @click="()=>dialog=false">취소</v-btn>
                <v-btn color="primary" text @click="edit">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog> 
</template>

<script>
import api from '@/api';
import dayjs from "dayjs";

export default {
    props:{
        item: { type: Object, default: ()=>({}) },
    },
    data(){
        return{
            dialog: false,
            limitation:{
                limitDates: [],
            },
            rule: {
                period: null,
                interval: null,
                day: null
            },
            periodItems:[
                { text: "한 달", value: "month" },
                { text: "세 달", value: "3months" },
                { text: "1년", value: "year" },
            ],
            dayItems:[
                { text: "일", value: 0 },
                { text: "월", value: 1 },
                { text: "화", value: 2 },
                { text: "수", value: 3 },
                { text: "목", value: 4 },
                { text: "금", value: 5 },
                { text: "토", value: 6 },
            ]
        }
    },
    mounted(){
        this.search();
    },
    methods:{
        async search(){
            try{
                let { limitation } = await api.console.apply.limitations.get();
                this.limitation = limitation;
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async edit(){
            try{
                await api.console.apply.limitations.put(this.limitation);

                this.dialog = false;
                alert("저장되었습니다.");
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        pushLimitDates(){
            let { period, day } = this.rule;
            let { limitDates } = this.limitation;
            let today = dayjs();
            let setArr;

            switch (period){
                case "month":
                    setArr = new Set([...limitDates, ...this.calculateLimitDates(today.add(1, "month"), day)]);
                    this.limitation.limitDates = [...setArr];
                    break;
                case "3months":
                    setArr = new Set([...limitDates, ...this.calculateLimitDates(today.add(3, "month"), day)]);
                    this.limitation.limitDates = [...setArr];
                    break;
                case "year":
                    setArr = new Set([...limitDates, ...this.calculateLimitDates(today.add(1, "year"), day)]);
                    this.limitation.limitDates = [...setArr];
                    break;
            }
        },
        calculateLimitDates(endDate, day){
            let limitDates = [];
            let today = dayjs();
            
            while(today.isBefore(endDate)){
                if(today.day() === day){
                    limitDates.push(today.format("YYYY-MM-DD"));
                }
                today = today.add(1, "day");
            }
            return limitDates;
        }
    },
}
</script>

<style>

</style>
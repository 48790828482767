<template>
    <v-container fluid fill-height class="console-notification">
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%">
                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">교육갤러리</v-col>
                    <v-spacer></v-spacer>
                </v-row>

                <v-card class="elevation-1 mt-8 mx-1">
                     <v-card-title class="subtitle-2 font-weight-bold">이미지 등록</v-card-title>
                </v-card>

                <v-layout>
                    <v-card v-for="gallery, index in galleries" width="200" height="auto" :key="index" class="shrink ma-2">

                        <v-card-text>
                            <v-row>
                                <v-col>
                                    제목
                                    <div>{{ gallery.subject }}</div>
                                </v-col>
                                <v-col>
                                    메인 노출
                                    <div>{{ gallery.isMain ? "노출" : "미노출" }}</div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-text v-if="gallery.image">
                            썸네일
                            <v-img :src="gallery.image" width="200" height="200" contain/>
                        </v-card-text>

                        <v-card-actions>
                            <v-btn text @click="edit(gallery)" class="mx-1">
                                수정
                                <v-icon right small>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn text @click="remove(gallery)" class="mx-1">
                                삭제
                                <v-icon right small >mdi-delete</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-layout>

                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>

                <v-btn fab fixed bottom right color="accent" @click="create"><v-icon>mdi-pencil</v-icon></v-btn>
                <v-dialog v-model="dialog" max-width="720px">
                    <v-card>
                        <v-card-title>
                            <span class="subtitle-1">교육 갤러리 등록</span>
                        </v-card-title>
                        <v-divider/>

                        <v-card-text>
                            <v-row class="mt-3">
                                <v-col class="py-0">
                                    <v-text-field v-model="editItem.subject" label="제목" hide-details class="mt-3"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="auto" class="py-0">
                                    <div>
                                        <div class="caption">메인노출</div>
                                        <v-switch v-model="editItem.isMain" class="mt-0" hide-details></v-switch>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row align="center" class="pt-2">
                                <v-col cols="auto" class="py-0">
                                    <v-img v-if="editItem.image" max-width="96" max-height="96" :src="createObjectURL(editItem.image)" contain></v-img>
                                    <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                        <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                                    </v-responsive>
                                </v-col>
                                <v-col class="py-0">
                                    <v-file-input v-model="editItem.image" label="이미지 등록_384×384(px)" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*"></v-file-input>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="close">취소</v-btn>
                            <v-btn color="primary" text @click="save(editItem)">저장</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";

export default {
    data() {
        return{
            page: 1,
            pageCount: 0,
            limit: 10,

            dialog: false,
            galleries: [],

            editItem: {
                _id: null,
                subject: "",
                isMain: false,
                image: null
            },

            defaultItem: {
                _id: null,
                subject: "",
                isMain: false,
                image: null
            }
        }
    },
    mounted(){
        this.init();
    },
    methods:{
        async init(){
            await this.search();
        },
        async search(){
            try{
                let { galleries } = await api.console.gallery.gets({
                    headers: { skip: this.skip, limit: this.limit },
                    params: this.filter
                });

                this.galleries = galleries;

            }catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async save(gallery){
            try{
                let { gallery: { _id }} = gallery._id ? await api.console.gallery.put(gallery) : await api.console.gallery.post(gallery);
                if(gallery.image){ await api.console.gallery.postThumb(_id, gallery.image)};

                alert("저장되었습니다.");
                await this.search();
                this.dialog= false;
            }catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        create(){
            this.editItem = Object.assign({}, this.defaultItem);
            this.dialog = true;
        },
        async edit(gallery){
            this.editItem = Object.assign({}, { _id: gallery._id, subject: gallery.subject, isMain: gallery.isMain });
            if(gallery.image){
                try{
                    this.editItem.image = await api.getResource(gallery.image,true)
                } catch(error){
                    console.error(error);
                    alert(error.response ? error.response.data.message : error.message);
                }
            }
            this.dialog= true;
        },
        async remove(gallery){
            try{
                if(confirm("갤러리를 삭제하시겠습니까?")){
                    await api.console.gallery.delete(gallery);
                    alert("삭제되었습니다.");
                    await this.search();
                }
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async close(){
            this.dialog = false;
        },
        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },
    }
}
</script>

<style>

</style>
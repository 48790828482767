<template>
    <v-container fluid fill-height class="console-notification">
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%">
                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">사업공고</v-col>
                    <v-spacer></v-spacer>
                    <!-- <v-col cols="auto" class="pt-0 pb-0">
                        <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                    </v-col> -->
                </v-row>

                <v-data-table :headers="businessesHeaders" :items="businesses" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                    <template #item.no="{ item }">
                        {{ (page - 1) * limit + businesses.indexOf(item) + 1 }}
                    </template>
                    <template #item.business="{ item }" style="height: auto">
                            <v-row @click="$set(item, 'show', !item.show)">
                                <v-col cols="auto" class="text-start">{{ item.subject }}</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto">
                                    <v-icon v-if="!item.show">mdi-chevron-down</v-icon>
                                    <v-icon v-else>mdi-chevron-up</v-icon>
                                </v-col>
                            </v-row>
                            <v-expand-transition>
                                <v-row v-show="item.show">
                                    <v-col cols="12" class="text-start">
                                        <p v-html="item.content.replace(/\n/g, '<br>')"></p>
                                    </v-col>
                                </v-row>
                            </v-expand-transition>
                    </template>
                    <template #item.isNotice="{ item }">
                        {{ item.isNotice ? "고정" : "미고정" }}
                        <!-- <v-switch v-model="item.isNotice" class="mt-0" hide-details @change="save(item)"></v-switch> -->
                    </template>
                    <template #item.createdAt="{ item }">
                        {{ item.createdAt.toDate() }}
                    </template>
                    <template #item.actions="{ item }">
                        <v-icon small class="mr-2" @click="edit(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon small @click="remove(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"  @input="search()"></v-pagination>

                <v-dialog v-model="businessDialog" max-width="820px">
                    <template #activator="{ on }">
                        <v-btn bottom color="accent" dark fab fixed right @click="create">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <v-card v-if="businessDialog">
                        <v-card-title>
                            <span v-if="!editItem._id" class="subtitle-1">공지사항 등록</span>
                            <span v-else class="subtitle-1">공지사항 수정</span>
                        </v-card-title>
                        <v-card-text>
                            <v-row class="mt-3">
                                <v-col class="py-0">
                                    <v-text-field v-model="editItem.subject" label="제목" persistent-placeholder hide-details></v-text-field>
                                </v-col>
                                <!-- <v-col cols="12" md="auto" class="py-0">
                                    <v-select v-model="editItem.locale" :items="locales" item-text="text" item-value="value" label="언어" hide-details></v-select>
                                </v-col> -->
                                <v-col cols="12" md="auto" class="py-0">
                                    <div>
                                        <div class="caption">상단고정</div>
                                        <v-switch v-model="editItem.isNotice" class="mt-0" hide-details></v-switch>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row align="center" class="mt-4">
                                <v-col class="py-0 pr-0">
                                    <v-file-input v-model="editItem.upload" multiple label="첨부파일" persistent-placeholder hide-details small-chips />
                                </v-col>
                            </v-row>
                            <div class="caption mt-4 mb-1">내용</div>
                            <naver-smarteditor v-model="editItem.content" rows="10"></naver-smarteditor>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="close">취소</v-btn>
                            <v-btn color="primary" text @click="save(editItem)">저장</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
export default {
    components: {
        NaverSmarteditor
    },
    name: "Business",
    created(){
        this.init();
    },
    data: () => {
        return {
            showSearch: false,

            page: 1,
            pageCount: 0,
            limit: 10,

            businessDialog: false,
            businesses: [],
            businessesHeaders: [{
                    text: "no",
                    align: "center",
                    width: 100,
                    value: "no"
                },
                {
                    text: '사업공고',
                    align: 'center',
                    value: 'business'
                },
                // {
                //     text: '언어',
                //     align: 'center',
                //     width: 120,
                //     value: 'locale'
                // },
                {
                    text: '작성일자',
                    align: 'center',
                    width: 120,
                    value: 'createdAt'
                },
                {
                    text: '상단고정',
                    align: 'center',
                    width: 80,
                    value: 'isNotice'
                },
                {
                    text: 'Actions',
                    align: 'center',
                    width: 100,
                    value: 'actions'
                }
            ],
            editItem: {
                _id: null,
                subject: "",
                content: "",
                // locale: "ko",
                upload: null
            },

            defaultItem: {
                _id: null,
                subject: "",
                content: "",
                // locale: "ko",
                upload: null
            },

            // locales: [
            //     { text: "한국어", value: "ko" },
            //     { text: "영어", value: "en" },
            //     { text: "중국어", value: "cn" },
            // ]
        };
    },
    methods: {
        async init(){
            try{
                await this.search();
            }
            catch(error){
                console.error(error);
            }
        },

        async search(){
            try{
                var { summary, businesses } = await api.console.center.businesses.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    }
                });

                this.businesses = businesses;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            }
            catch(error){
                console.error(error);
            }
        },

        close(){
            this.businessDialog = false;
        },

        create(){
            this.editItem = Object.assign({}, this.defaultItem);
            this.businessDialog = true;
        },

        async edit(business){
            this.editItem = Object.assign({}, business);
            if(business.upload){ 
                try{
                    this.editItem.upload = await Promise.all(business.upload.map(async(file)=> await api.getResource(file)))
                } catch(error){
                    alert(error.message)
                    console.error(error);
                }
            }
            this.businessDialog = true;
        },

        async save(business){
            let { business: { _id } } = business._id ? await api.console.center.businesses.put(business) : await api.console.center.businesses.post(business);
            let upload = [];
            if(business.upload){
                // 기존 파일 삭제
                await api.console.center.businesses.upload.delete(_id);

                // 다중파일 업로드
                await Promise.all(business.upload.map(async (file) => {
                    return api.console.center.businesses.upload.post(_id, file);
                })).then(async(result) => {
                    //파일 경로 저장
                    

                    result.forEach((item) => {
                        upload.push(item.upload);
                    });

                    await api.console.center.businesses.upload.put({ _id, upload });
                }).catch((error) => {
                    console.error(error);
                });
            }
            alert("저장되었습니다.");
            await this.search();
            this.businessDialog = false;
        },

        async remove(business){
            try{
                if(confirm("공지사항을 삭제하시겠습니까?")){
                    if(business.upload) await api.console.center.businesses.upload.delete(business._id);
                    await api.console.center.businesses.delete(business);
                    alert("삭제되었습니다.")
                    await this.search();
                }
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },
    }
}
</script>

<style>
.console-notification .v-data-table__mobile-row { height: auto !important; }
</style>

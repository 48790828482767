<template>
    <v-dialog v-if="events" v-model="dialog" max-width="940px">
        <template v-slot:activator="{ on }">
            <v-btn class="mr-16" bottom color="blue lighten-1" fab fixed right v-on="on" @click="()=>search()">
                <v-icon>mdi-calendar-multiple-check</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="subtitle-1">캘린더 미리보기</span>
            </v-card-title>
            <v-divider/>

            <v-card-text>
                <div class="pb-30px pb-md-40px">
                    <v-row align="center" justify="center">
                        <v-col cols="auto">
                            <v-btn icon class="ma-2" @click="goPreviousMonth" >
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-toolbar-title>{{ $dayjs(date).format("YYYY년 MM월")}}</v-toolbar-title>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn icon class="ma-2" @click="goNextMonth" >
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
                <v-sheet height="600" style="margin: -5px">
                    <v-calendar 
                        ref="calendar" 
                        type="month"
                        event-overlap-mode="stack"
                        v-model="date" 
                        :events="events" 
                        :day-format="dayFormat" 
                        :show-month-on-first="false" 
                        :event-overlap-threshold="30"
                        :event-more="false"
                        color="primary" event-text-color="grey" event-color="grey lighten-5" 
                        >

                        <template v-slot:event="{ event }">
                            {{ event.name }}
                        </template>
                    </v-calendar>
                </v-sheet>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="()=>dialog=false">취소</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog> 
</template>

<script>
import api from '@/api';
import dayjs from "dayjs";

export default {
    props:{
        item: { type: Object, default: ()=>({}) },
    },
    data(){
        return{
            date: Date.now().toDate(),
            events: [],
            dialog: false,
        }
    },
    mounted(){
        this.search();
    },
    methods:{
        async search(){
            let events= [];
            try{
                let { schedules } = await api.v1.schedule.gets({
                    params:{
                        startOfMonth: this.dates[0],
                        endOfMonth: this.dates[1],
                        code: this.$route.query.code
                    }
                });

                schedules.forEach((schedule)=> events.push({ 
                    name: schedule.company, 
                    start: this.$dayjs(schedule.scheduleDate).format("YYYY-MM-DD"),
                    end: this.$dayjs(schedule.scheduleDate).format("YYYY-MM-DD"),
                    color: "red lighten-5",
                }));

                this.events = events;
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        goPreviousMonth() {
            this.date = dayjs(this.date).startOf("month").subtract(1, "month").toISOString().toDate();
        },
        goNextMonth() {
            this.date = dayjs(this.date).startOf("month").add(1, "month").toISOString().toDate();
        },
        dayFormat({ day }) {
            return day;
        },
    },
    computed: {
        yearMonth(){
            return dayjs(this.date).month() + 1;
        },
        dates() {
            let start = dayjs(this.date).startOf("month").startOf('week');
            let end = dayjs(this.date).endOf("month").endOf('week');

            return [start, end].map((date) => date.toISOString());
        }
    },
    watch:{
        $route(){
            this.search();
        },
        date(){
            this.search();
        }
    }
}
</script>

<style>

</style>
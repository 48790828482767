var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-board-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [!_vm.$route.query._board ? _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("장비 등록")]) : _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("장비 상세")]), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "장비명",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.equipment.title,
      callback: function ($$v) {
        _vm.$set(_vm.equipment, "title", $$v);
      },
      expression: "equipment.title"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-select', {
    attrs: {
      "items": _vm.workItems,
      "item-text": "text",
      "item-value": "value",
      "label": "작업실",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.equipment.work,
      callback: function ($$v) {
        _vm.$set(_vm.equipment, "work", $$v);
      },
      expression: "equipment.work"
    }
  })], 1), _vm.equipment.work === _vm.DDM_WORK_TYPES.STUDIO.value ? _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-select', {
    attrs: {
      "items": _vm.spaceItems,
      "item-text": "text",
      "item-value": "value",
      "label": "센터",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.equipment.center,
      callback: function ($$v) {
        _vm.$set(_vm.equipment, "center", $$v);
      },
      expression: "equipment.center"
    }
  })], 1) : _vm._e(), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-checkbox', {
    attrs: {
      "item-text": "text",
      "item-value": "value",
      "label": "메인 노출 여부",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.equipment.isMain,
      callback: function ($$v) {
        _vm.$set(_vm.equipment, "isMain", $$v);
      },
      expression: "equipment.isMain"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "이용 시간",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.equipment.time,
      callback: function ($$v) {
        _vm.$set(_vm.equipment, "time", $$v);
      },
      expression: "equipment.time"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "위치",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.equipment.location,
      callback: function ($$v) {
        _vm.$set(_vm.equipment, "location", $$v);
      },
      expression: "equipment.location"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "수량",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.equipment.quantity,
      callback: function ($$v) {
        _vm.$set(_vm.equipment, "quantity", $$v);
      },
      expression: "equipment.quantity"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("썸네일")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.equipment.thumb ? _c('v-img', {
    attrs: {
      "max-width": "96",
      "max-height": "96",
      "src": _vm.createObjectURL(_vm.equipment.thumb),
      "contain": ""
    }
  }) : _c('v-responsive', {
    staticClass: "grey lighten-2",
    attrs: {
      "width": "96",
      "height": "96"
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-file-input', {
    staticClass: "mt-2 mx-3",
    attrs: {
      "label": "썸네일 이미지_282×164(px)",
      "persistent-placeholder": "",
      "show-size": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.equipment.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.equipment, "thumb", $$v);
      },
      expression: "equipment.thumb"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("내용")]), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "id": "content",
      "rows": "10"
    },
    model: {
      value: _vm.equipment.content,
      callback: function ($$v) {
        _vm.$set(_vm.equipment, "content", $$v);
      },
      expression: "equipment.content"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
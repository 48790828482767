<template>
    <v-dialog v-model="dialog" max-width="720px">
        <template v-slot:activator="{ on }">
            <v-btn bottom color="accent" fab fixed right v-on="on" @click="clean()">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="subtitle-1">스케쥴 등록</span>
            </v-card-title>
            <v-divider/>

            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field v-model="editItem.company" label="업체명" hide-details class="mt-3"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="editItem.equipment" label="사용기기" hide-details class="mt-3"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="editItem.quantity" label="수량" hide-details class="mt-3"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-3">
                    <v-col cols="6" class="py-0">
                        <v-date-field v-model="editItem.scheduleDate" label="스케쥴 일자" hide-details class="mt-3"></v-date-field>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="()=>dialog=false">취소</v-btn>
                <v-btn color="primary" text @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog> 
</template>

<script>
import api from "@/api";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

export default {
    components:{
        VDateField
    },
    props:{
        item: { type: Object, default: ()=>({}) },
    },
    data(){
        return{
            dialog: false,
            editItem: {},
        }
    },
    methods:{
        async save(){
            try{
                if(!this.validation()) return false;
                let { put, post } = api.console.schedule;

                this.editItem._id ? put(this.editItem) : post({...this.editItem, work: this.$route.query.code});

                alert("저장되었습니다.");
                this.dialog = false;
                this.$emit('search');
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async edit(schedule){
            try{
                this.editItem = { ...schedule, 
                                    scheduleDate: this.$dayjs(schedule.scheduleDate).format("YYYY-MM-DD"),
                                };
                this.dialog = true;
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        clean(){
            this.editItem= {};
        },
        validation(){
            if(!this.editItem.company) throw new Error("업체명을 입력해주세요.");
            if(!this.editItem.equipment) throw new Error("사용기기를 입력해주세요.");
            if(!this.editItem.quantity) throw new Error("수량을 입력해주세요.");
            if(!this.editItem.scheduleDate) throw new Error("스케쥴 일자를 입력해주세요.");

            return true;
        }
    },
    watch:{
        item(schedule){
            this.edit(schedule);
        }
    }
}
</script>

<style>

</style>
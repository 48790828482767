var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-board-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [!_vm.$route.query._board ? _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("교육 등록")]) : _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("교육 상세")]), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "교육명",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.education.title,
      callback: function ($$v) {
        _vm.$set(_vm.education, "title", $$v);
      },
      expression: "education.title"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "모집인원",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.education.capacity,
      callback: function ($$v) {
        _vm.$set(_vm.education, "capacity", $$v);
      },
      expression: "education.capacity"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "모집상태",
      "persistent-placeholder": "",
      "hide-details": "",
      "disabled": ""
    },
    model: {
      value: _vm.education.status,
      callback: function ($$v) {
        _vm.$set(_vm.education, "status", $$v);
      },
      expression: "education.status"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-datetime-field', {
    attrs: {
      "label": "지원기간 시작일",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.setStatus
    },
    model: {
      value: _vm.education.enrollmentStartDate,
      callback: function ($$v) {
        _vm.$set(_vm.education, "enrollmentStartDate", $$v);
      },
      expression: "education.enrollmentStartDate"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-datetime-field', {
    attrs: {
      "label": "지원기간 종료일",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.setStatus
    },
    model: {
      value: _vm.education.enrollmentEndDate,
      callback: function ($$v) {
        _vm.$set(_vm.education, "enrollmentEndDate", $$v);
      },
      expression: "education.enrollmentEndDate"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-datetime-field', {
    attrs: {
      "label": "교육기간 시작일",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.education.educationStartDate,
      callback: function ($$v) {
        _vm.$set(_vm.education, "educationStartDate", $$v);
      },
      expression: "education.educationStartDate"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-datetime-field', {
    attrs: {
      "label": "교육기간 종료일",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.education.educationEndDate,
      callback: function ($$v) {
        _vm.$set(_vm.education, "educationEndDate", $$v);
      },
      expression: "education.educationEndDate"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-3"
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("첨부파일")]), _c('v-card-text', [_c('v-file-input', {
    attrs: {
      "multiple": "",
      "label": "첨부파일",
      "persistent-placeholder": "",
      "hide-details": "",
      "small-chips": ""
    },
    model: {
      value: _vm.education.upload,
      callback: function ($$v) {
        _vm.$set(_vm.education, "upload", $$v);
      },
      expression: "education.upload"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("내용")]), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "id": "content",
      "rows": "10"
    },
    model: {
      value: _vm.education.content,
      callback: function ($$v) {
        _vm.$set(_vm.education, "content", $$v);
      },
      expression: "education.content"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "950px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-col', [_c('v-row', [_c('span', {
    staticClass: "text-md-h5"
  }, [_vm._v("작업실 신청 상세")])])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "신청자명",
      "readonly": "",
      "hide-details": ""
    },
    model: {
      value: _vm.application.name,
      callback: function ($$v) {
        _vm.$set(_vm.application, "name", $$v);
      },
      expression: "application.name"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "연락처",
      "readonly": "",
      "hide-details": ""
    },
    model: {
      value: _vm.application.phone,
      callback: function ($$v) {
        _vm.$set(_vm.application, "phone", $$v);
      },
      expression: "application.phone"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "작성일자",
      "readonly": "",
      "hide-details": ""
    },
    model: {
      value: _vm.createdAt,
      callback: function ($$v) {
        _vm.createdAt = $$v;
      },
      expression: "createdAt"
    }
  })], 1), _c('v-col', [_c('v-select', {
    staticClass: "mt-3",
    attrs: {
      "label": "승인여부",
      "items": _vm.statusItems
    },
    model: {
      value: _vm.application.status,
      callback: function ($$v) {
        _vm.$set(_vm.application, "status", $$v);
      },
      expression: "application.status"
    }
  })], 1)], 1), _c('v-divider'), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "신청일자",
      "readonly": "",
      "hide-details": ""
    },
    model: {
      value: _vm.applyDate,
      callback: function ($$v) {
        _vm.applyDate = $$v;
      },
      expression: "applyDate"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "신청 시간",
      "readonly": "",
      "hide-details": ""
    },
    model: {
      value: _vm.application.applyTime,
      callback: function ($$v) {
        _vm.$set(_vm.application, "applyTime", $$v);
      },
      expression: "application.applyTime"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-checkbox', {
    attrs: {
      "readonly": "",
      "label": "본봉13,오버록",
      "value": "본봉13,오버록"
    },
    model: {
      value: _vm.application.equipments,
      callback: function ($$v) {
        _vm.$set(_vm.application, "equipments", $$v);
      },
      expression: "application.equipments"
    }
  })], 1), _c('v-col', [_c('v-checkbox', {
    attrs: {
      "readonly": "",
      "label": "본봉14,오버록",
      "value": "본봉14,오버록"
    },
    model: {
      value: _vm.application.equipments,
      callback: function ($$v) {
        _vm.$set(_vm.application, "equipments", $$v);
      },
      expression: "application.equipments"
    }
  })], 1), _c('v-col', [_c('v-checkbox', {
    attrs: {
      "readonly": "",
      "label": "본봉15,오버록",
      "value": "본봉15,오버록"
    },
    model: {
      value: _vm.application.equipments,
      callback: function ($$v) {
        _vm.$set(_vm.application, "equipments", $$v);
      },
      expression: "application.equipments"
    }
  })], 1), _c('v-col', [_c('v-checkbox', {
    attrs: {
      "readonly": "",
      "label": "날날이",
      "value": "날날이"
    },
    model: {
      value: _vm.application.equipments,
      callback: function ($$v) {
        _vm.$set(_vm.application, "equipments", $$v);
      },
      expression: "application.equipments"
    }
  })], 1), _c('v-col', [_c('v-checkbox', {
    attrs: {
      "readonly": "",
      "label": "캐드",
      "value": "캐드"
    },
    model: {
      value: _vm.application.equipments,
      callback: function ($$v) {
        _vm.$set(_vm.application, "equipments", $$v);
      },
      expression: "application.equipments"
    }
  })], 1), _c('v-col', [_c('v-checkbox', {
    attrs: {
      "readonly": "",
      "label": "오버록",
      "value": "오버록"
    },
    model: {
      value: _vm.application.equipments,
      callback: function ($$v) {
        _vm.$set(_vm.application, "equipments", $$v);
      },
      expression: "application.equipments"
    }
  })], 1), _c('v-col', [_c('v-checkbox', {
    attrs: {
      "readonly": "",
      "label": "마네킹",
      "value": "마네킹"
    },
    model: {
      value: _vm.application.equipments,
      callback: function ($$v) {
        _vm.$set(_vm.application, "equipments", $$v);
      },
      expression: "application.equipments"
    }
  })], 1)], 1), _c('v-row', [_c('v-textarea', {
    attrs: {
      "readonly": ""
    },
    model: {
      value: _vm.application.purpose,
      callback: function ($$v) {
        _vm.$set(_vm.application, "purpose", $$v);
      },
      expression: "application.purpose"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function () {
        return _vm.dialog = false;
      }
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.edit
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
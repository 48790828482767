<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">교육 안내</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[0]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[1]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-3">
                            <v-col cols="6" md="3" class="py-0">
                                <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" @change="()=> {filter.searchValue=null; filter.period=[];}" hide-details persistent-placeholder></v-select>
                            </v-col>
                            <v-col cols="6" md="3" class="py-0" v-if="[ null,'title'].includes(filter.searchKey)">
                                <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details :disabled="!filter.searchKey"  @keydown.enter="(page = 1) && search()"></v-text-field>
                            </v-col>
                            <v-col cols="6" md="3" class="py-0" v-if="['educationPeriod', 'enrollmentPeriod'].includes(filter.searchKey)" >
                                <v-date-field v-model="filter.period[0]" label="시작일" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                            <v-col cols="6" md="3" class="py-0" v-if="['educationPeriod', 'enrollmentPeriod'].includes(filter.searchKey)" >
                                <v-date-field v-model="filter.period[1]" label="종료일" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                            <v-col cols="6" md="3" class="py-0" v-if="['isClosed'].includes(filter.searchKey)" >
                                <v-select v-model="filter.searchValue" :items="closedKeys" label="수동마감여부" hide-details persistent-placeholder></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="(page = 1) && search()"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>
                
            <v-data-table v-bind="{ items: educations, headers: educationtHeaders }" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template v-for="header in educationtHeaders.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.no`]="{ item }">
                    {{ (page - 1) * limit + (+educations.indexOf(item) + 1) }}
                </template>
                <template #[`item.educationPeriod`]="{ item }">
                    {{ $dayjs(item.educationStartDate).format("YYYY-MM-DD") }} ~ {{ $dayjs(item.educationEndDate).format("YYYY-MM-DD") }}
                </template>
                <template #[`item.enrollmentPeriod`]="{ item }">
                    {{ $dayjs(item.enrollmentStartDate).format("YYYY-MM-DD HH:mm") }} ~ {{ $dayjs(item.enrollmentEndDate).format("YYYY-MM-DD HH:mm") }}
                </template>
                <template #[`item.status`]="{ item }">
                    {{ item.isClosed ? ENROLLMENT_STATUS.CLOSED.text : setStatus(item.enrollmentStartDate, item.enrollmentEndDate) }}
                </template>
                <template #[`item.isClosed`]="{ item }">
                    <v-switch v-model="item.isClosed" hide-details dense class="mt-0" @change="modifyIsClosed({ _id: item._id, isClosed: item.isClosed})"></v-switch>
                </template>
                <template #[`item.createdAt`]="{ item }">
                    {{ item.createdAt.toDateTime() }}
                </template>
                <template #[`item.actions`]="{item}">
                    <v-icon small @click="edit(item)" title="수정">mdi-pencil</v-icon>
                    <v-icon v-if="false" small @click="copy(item)" title="복사" class="ml-2">mdi-content-copy</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>

            <v-btn bottom color="accent" fab fixed right @click="create()">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { ENROLLMENT_STATUS } from "@/assets/variables";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

const educationtHeaders = [
    { text: "no", align: "center", width: 40, value: "no" },
    { text: "제목", align: "center", width: 200, value: "title" },
    { text: "모집인원", align: "center", width: 70, value: "capacity" },
    { text: "교육기간", align: "center", width: 150, value: "educationPeriod" },
    { text: "접수기간", align: "center", width: 150, value: "enrollmentPeriod" },
    { text: "모집상태", align: "center", width: 70, value: "status" },
    { text: "수동마감처리", align: "center", width: 50, value: "isClosed" },
    { text: "작성일", align: "center", width: 70, value: "createdAt" },
    { text: "Actions", width: 40, align: "center", value: "actions" },
];

export default {
    components: {
        VDateField,
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,

            filter: {
                searchKey: this.$route.query.searchKey,
                searchValue: this.$route.query.searchValue,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
                period: [(this.$route.query.period || [])[0], (this.$route.query.period || [])[1]],
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            educations: [],
            educationtHeaders,

            searchKeys: [
                { text: "전체", value: null },
                { text: "제목", value: "title" },
                { text: "교육기간", value: "educationPeriod" },
                { text: "접수기간", value: "enrollmentPeriod" },
                { text: "수동마감여부", value: "isClosed"}
            ],

            closedKeys: [
                { text: "전체", value: null },
                { text: "처리", value: true },
                { text: "미처리", value: false },
            ],
            
            searchKey: null,
            searchValue: null,
            ENROLLMENT_STATUS
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.search(false);
        },
        async search(routable = true) {
            try {
                if (routable) {
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page }),
                    });
                }

                var { summary, educations } = await api.console.education.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.pageCount = Math.ceil(summary.totalCount / this.limit);
                this.educations = educations;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        create() {
            this.$router.push(`/console/edu-education/create`);
        },
        edit(education) {
            this.$router.push(`/console/edu-education/${education._id}`);
        },
        async remove(education) {
            try {
                if (confirm("게시글을 삭제하시겠습니까?")) {
                    if(education.upload) await api.console.education.upload.delete(education._id);
                    await api.console.education.delete({_id: education._id});
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        setStatus(start, end){
            const now = this.$dayjs().valueOf();
            start = this.$dayjs(start).valueOf();
            end = this.$dayjs(end).valueOf();
            
            if(now < start){
              return ENROLLMENT_STATUS.PLANNED.text;
            };

            if(start <= now && now <= end){              
              return ENROLLMENT_STATUS.IN_PROGRESS.text;
            };
            
            if(end < now){              
              return ENROLLMENT_STATUS.CLOSED.text;
            };

            return null;
        }, 
        async modifyIsClosed(value){
            try{
                await api.console.education.put(value)
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        }
    },
    watch: {
        $route() {
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
        },
    },
};
</script>

<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <div v-if="!$route.query._board" class="headline text-start mt-8">공간 등록</div>
            <div v-else class="headline text-start mt-8">공간 상세</div>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1 pb-0">기본정보</v-card-title>
                <v-card-text>
                    <v-row class="mt-3">
                        <v-col class="py-0">
                            <v-text-field v-model="space.title" label="공간명" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col class="py-0">
                            <v-select v-model="space.center" :items="spaceItems" item-text="text" item-value="value" label="센터" persistent-placeholder hide-details></v-select>
                        </v-col>
                        <v-col class="py-0">
                            <v-checkbox v-model="space.isMain" item-text="text" item-value="value" label="메인 노출 여부" persistent-placeholder hide-details></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3">
                        <v-col class="py-0">
                            <v-text-field v-model="space.time" label="이용 시간" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col class="py-0">
                            <v-text-field v-model="space.location" label="위치" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col class="py-0">
                            <v-text-field v-model="space.capacity" label="면적" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1">썸네일 </v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="auto" class="py-0">
                            <v-img v-if="space.thumb" max-width="96" max-height="96" :src="createObjectURL(space.thumb)" contain></v-img>
                            <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                            </v-responsive>
                        </v-col>
                        <v-col class="py-0">
                            <v-file-input v-model="space.thumb" label="썸네일 이미지_282×164(px)" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*"></v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-6">
                <v-card-title class="subtitle-1">내용</v-card-title>
                <v-card-text>
                    <naver-smarteditor v-model="space.content" id="content" rows="10"></naver-smarteditor>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>

        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import { DDM_CENTER_TYPES } from "@/assets/variables"

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

import { mdiTagPlus } from "@mdi/js";
import urlParser from "js-video-url-parser";
import YoutubeCategoriesView from "@/components/console/boards/youtube-categories-view.vue";
import ReportViewCategories from "@/components/console/boards/report-view-categories.vue";
import FormsViewCategories from "@/components/console/boards/forms-view-categories.vue";

export default {
    components: {
        VDatetimeField,
        DaumPostcode,
        NaverSmarteditor,
        YoutubeCategoriesView,
        ReportViewCategories,
        FormsViewCategories,
    },
    data() {
        return {
            mdiTagPlus,
            ready: false,

            spaceItems: [
                DDM_CENTER_TYPES.YONGSIN,
                DDM_CENTER_TYPES.DAPSIMNI,
            ],

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            space: {
              title: "",
              center: "",
              time: "",
              location: "",
              content: "",
              capacity:"",
              isMain: false,
              thumb: null,
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (this.$route.params._space) {
                    var { space } = await api.console.space.get({ _id: this.$route.params._space });
                    if (space.thumb) space.thumb = await api.getResource(space.thumb, true);
                    this.space = space;
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                if(!this.validation()) return;
                let { thumb, ...space } = this.space;
                let { put, post } = api.console.space;
                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                ({ space } = space._id ? await put(space) : await post(space));

                //////////////////////////////////////////////////////////////
                // 2. 리소스 저장
                //////////////////////////////////////////////////////////////
                if (thumb) await api.console.space.postThumb(space, thumb);

                alert(this.space._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },

        validation(){
            if(!this.space.center) throw new Error("센터를 설정해주세요.");

            return true;
        }
    },
};
</script>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "console-notification",
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1280px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("교육갤러리")]), _c('v-spacer')], 1), _c('v-card', {
    staticClass: "elevation-1 mt-8 mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("이미지 등록")])], 1), _c('v-layout', _vm._l(_vm.galleries, function (gallery, index) {
    return _c('v-card', {
      key: index,
      staticClass: "shrink ma-2",
      attrs: {
        "width": "200",
        "height": "auto"
      }
    }, [_c('v-card-text', [_c('v-row', [_c('v-col', [_vm._v(" 제목 "), _c('div', [_vm._v(_vm._s(gallery.subject))])]), _c('v-col', [_vm._v(" 메인 노출 "), _c('div', [_vm._v(_vm._s(gallery.isMain ? "노출" : "미노출"))])])], 1)], 1), gallery.image ? _c('v-card-text', [_vm._v(" 썸네일 "), _c('v-img', {
      attrs: {
        "src": gallery.image,
        "width": "200",
        "height": "200",
        "contain": ""
      }
    })], 1) : _vm._e(), _c('v-card-actions', [_c('v-btn', {
      staticClass: "mx-1",
      attrs: {
        "text": ""
      },
      on: {
        "click": function ($event) {
          return _vm.edit(gallery);
        }
      }
    }, [_vm._v(" 수정 "), _c('v-icon', {
      attrs: {
        "right": "",
        "small": ""
      }
    }, [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
      staticClass: "mx-1",
      attrs: {
        "text": ""
      },
      on: {
        "click": function ($event) {
          return _vm.remove(gallery);
        }
      }
    }, [_vm._v(" 삭제 "), _c('v-icon', {
      attrs: {
        "right": "",
        "small": ""
      }
    }, [_vm._v("mdi-delete")])], 1)], 1)], 1);
  }), 1), _c('v-pagination', {
    staticClass: "mt-4 mb-12",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function ($event) {
        return _vm.search();
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }), _c('v-btn', {
    attrs: {
      "fab": "",
      "fixed": "",
      "bottom": "",
      "right": "",
      "color": "accent"
    },
    on: {
      "click": _vm.create
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-dialog', {
    attrs: {
      "max-width": "720px"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v("교육 갤러리 등록")])]), _c('v-divider'), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "제목",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.subject,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "subject", $$v);
      },
      expression: "editItem.subject"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', [_c('div', {
    staticClass: "caption"
  }, [_vm._v("메인노출")]), _c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.isMain,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "isMain", $$v);
      },
      expression: "editItem.isMain"
    }
  })], 1)])], 1), _c('v-row', {
    staticClass: "pt-2",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.editItem.image ? _c('v-img', {
    attrs: {
      "max-width": "96",
      "max-height": "96",
      "src": _vm.createObjectURL(_vm.editItem.image),
      "contain": ""
    }
  }) : _c('v-responsive', {
    staticClass: "grey lighten-2",
    attrs: {
      "width": "96",
      "height": "96"
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-file-input', {
    staticClass: "mt-2 mx-3",
    attrs: {
      "label": "이미지 등록_384×384(px)",
      "persistent-placeholder": "",
      "show-size": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.editItem.image,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "image", $$v);
      },
      expression: "editItem.image"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.save(_vm.editItem);
      }
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-dialog v-model="dialog" max-width="950px">
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title>
                <v-col>
                    <v-row>
                        <span class="text-md-h5">작업실 신청 상세</span>
                    </v-row>
                </v-col>
            </v-card-title>
            <v-divider/>

            <v-card-text>
                <v-row class="mt-3">
                    <v-col>
                        <v-text-field v-model="application.name" label="신청자명" readonly hide-details class="mt-3"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="application.phone" label="연락처" readonly hide-details class="mt-3"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="createdAt" label="작성일자" readonly hide-details class="mt-3"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-select v-model="application.status" label="승인여부" :items="statusItems" class="mt-3" />
                    </v-col>
                </v-row>
                <v-divider/>
                <v-row class="mt-3">
                    <v-col cols="6" class="py-0">
                        <v-text-field v-model="applyDate" label="신청일자" readonly hide-details class="mt-3"></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-0">
                        <v-text-field v-model="application.applyTime" label="신청 시간" readonly hide-details class="mt-3"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-checkbox v-model="application.equipments" readonly label="본봉13,오버록" value="본봉13,오버록"/>
                    </v-col>
                    <v-col>
                        <v-checkbox v-model="application.equipments" readonly label="본봉14,오버록" value="본봉14,오버록"/>
                    </v-col>
                    <v-col>
                        <v-checkbox v-model="application.equipments" readonly label="본봉15,오버록" value="본봉15,오버록"/>
                    </v-col>
                    <v-col>
                        <v-checkbox v-model="application.equipments" readonly label="날날이" value="날날이"/>
                    </v-col>
                    <v-col>
                        <v-checkbox v-model="application.equipments" readonly label="캐드" value="캐드"/>
                    </v-col>
                    <v-col>
                        <v-checkbox v-model="application.equipments" readonly label="오버록" value="오버록"/>
                    </v-col>
                    <v-col>
                        <v-checkbox v-model="application.equipments" readonly label="마네킹" value="마네킹"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-textarea v-model="application.purpose" readonly />
                </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="()=>dialog=false">취소</v-btn>
                <v-btn color="primary" text @click="edit">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog> 
</template>

<script>
import api from '@/api';
import dayjs from "dayjs";
import { APPLICATION_STATUS } from '@/assets/variables';

export default {
    props:{
        value: { type: Object, default: ()=>({}) },
    },
    data(){
        return{
            dialog: false,
            application: {},
            statusItems: [
                {text: APPLICATION_STATUS.WAITING.text, value: APPLICATION_STATUS.WAITING.value},
                {text: APPLICATION_STATUS.APPROVED.text, value: APPLICATION_STATUS.APPROVED.value},
                {text: APPLICATION_STATUS.REJECTED.text, value: APPLICATION_STATUS.REJECTED.value},
            ]
        }
    },
    methods:{
        async search(){
            try{
                const { application } = await api.console.apply.applications.get({_id: this._application});
                this.application = application;

            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async edit(){
            try{
                await api.console.apply.applications.put(this.application);

                this.dialog = false;
                alert("저장되었습니다.");
                this.$emit('search');
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
    computed: {
        _application(){ 
            return this.value._id; 
        },
        createdAt(){
            return this.$dayjs(this.value.createdAt).format('YYYY-MM-DD');
        },
        applyDate(){
            return this.$dayjs(this.value.applyDate).format('YYYY-MM-DD');
        }
    },
    watch: {
        dialog(){
            if(this.dialog) this.search();
        }
    }
}
</script>

<style>

</style>
<template>
    <v-layout justify-center>
        <v-responsive max-width="1600" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">작업실 신청 안내</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[0]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[1]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-3">
                            <v-col cols="6" md="3" class="py-0">
                                <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" @change="()=> {filter.searchValue=null; filter.period=[];}" hide-details persistent-placeholder></v-select>
                            </v-col>
                            <v-col cols="6" md="3" class="py-0" v-if="[ null,'name'].includes(filter.searchKey)">
                                <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details :disabled="!filter.searchKey"  @keydown.enter="(page = 1) && search()"></v-text-field>
                            </v-col>
                            <v-col cols="6" md="3" class="py-0" v-if="['applyDate'].includes(filter.searchKey)" >
                                <v-date-field v-model="filter.period[0]" label="시작일" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                            <v-col cols="6" md="3" class="py-0" v-if="['applyDate'].includes(filter.searchKey)" >
                                <v-date-field v-model="filter.period[1]" label="종료일" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                            <v-col cols="6" md="3" class="py-0" v-if="['status'].includes(filter.searchKey)" >
                                <v-select v-model="filter.searchValue" :items="statusItems" label="승인여부" hide-details persistent-placeholder></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="(page = 1) && search()"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>
                
            <v-data-table v-bind="{ items: applications, headers: applicationHeaders }" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template v-for="header in applicationHeaders.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.no`]="{ item }">
                    {{ (page - 1) * limit + (+applications.indexOf(item) + 1) }}
                </template>
                <template #[`item.equipments`]="{ item }">
                    {{ item.equipments.join(" / ")}}
                </template>
                <template #[`item.applyDateTime`]="{ item }">
                    {{ `${$dayjs(item.applyDate).format("YYYY-MM-DD")} / ${item.applyTime}` }}
                </template>
                <template #[`item.status`]="{ item, value }">
                    <v-edit-dialog :return-value="item.state" large cancel-text="취소" save-text="변경" @open="status = value" @save="updateStatus(item)">
                        {{ APPLICATION_STATUS[value].text }}
                        <template #input><v-select v-model="status" :items="statusItems" class="mt-3" /> </template>
                    </v-edit-dialog>
                </template>
                <template #[`item.actions`]="{item}">
                    <apply-view :value="item"  @search="search()">
                        <template #activator="{ attrs, on }">
                            <v-icon small v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                        </template>
                    </apply-view>
                    <v-icon v-if="false" small @click="copy(item)" title="복사" class="ml-2">mdi-content-copy</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>

            <apply-date/>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { APPLICATION_STATUS } from '@/assets/variables';

import ApplyView from "@/components/console/apply/apply-view.vue";
import ApplyDate from "@/components/console/apply/apply-date.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

const applicationHeaders = [
    { text: "no", align: "center", width: 40, value: "no" },
    { text: "신청일", align: "center", width: 80, value: "applyDateTime" },
    { text: "신청자", align: "center", width: 50, value: "name" },
    { text: "대여장비", align: "center", width: 100, value: "equipments" },
    { text: "신청사유", align: "center", width: 100, value: "purpose" },
    { text: "승인여부", align: "center", width: 70, value: "status" },
    { text: "Actions", width: 40, align: "center", value: "actions" },
];

export default {
    components: {
      ApplyDate,
      VDateField,
      ApplyView
    },
    data() {
        return {
            APPLICATION_STATUS,
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,

            filter: {
                searchKey: this.$route.query.searchKey,
                searchValue: this.$route.query.searchValue,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
                period: [(this.$route.query.period || [])[0], (this.$route.query.period || [])[1]],
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            applications: [],
            applicationHeaders,

            searchKeys: [
                { text: "전체", value: null },
                { text: "신청자", value: "name" },
                { text: "신청일", value: "applyDate" },
                { text: "승인여부", value: "status" },
            ],
            searchKey: null,
            searchValue: null,

            status: "",
            statusItems: [
                {text: APPLICATION_STATUS.WAITING.text, value: APPLICATION_STATUS.WAITING.value},
                {text: APPLICATION_STATUS.APPROVED.text, value: APPLICATION_STATUS.APPROVED.value},
                {text: APPLICATION_STATUS.REJECTED.text, value: APPLICATION_STATUS.REJECTED.value},
            ]
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.search(false);
        },
        async search(routable = true) {
            try {
                if (routable) {
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page }),
                    });
                }

                var { summary, applications } = await api.console.apply.applications.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.pageCount = Math.ceil(summary.totalCount / this.limit);
                this.applications = applications;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async remove(application) {
            try {
                    if (confirm("게시글을 삭제하시겠습니까?")) {
                    await api.console.apply.applications.delete({_id: application._id});
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            } catch (error) {
                    console.error(error);

                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async updateStatus(item){
            try{
                let { application } = await api.console.apply.applications.put({
                    ...item,
                    status: this.status
                });
                
                alert("처리되었습니다");
                await this.search(false);
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <div v-if="!$route.query._board" class="headline text-start mt-8">교육 등록</div>
            <div v-else class="headline text-start mt-8">교육 상세</div>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1 pb-0">기본정보</v-card-title>
                <v-card-text>
                    <v-row class="mt-3">
                        <v-col class="py-0">
                            <v-text-field v-model="education.title" label="교육명" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col class="py-0">
                            <v-text-field v-model="education.capacity" label="모집인원" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col class="py-0">
                            <v-text-field v-model="education.status" label="모집상태" persistent-placeholder hide-details disabled></v-text-field>
                        </v-col>
                        <!-- <v-col class="py-0">
                            <v-switch v-model="education.isClosed" label="마감" persistent-placeholder hide-details></v-switch>
                        </v-col> -->
                    </v-row>
                    <v-row class="mt-3">
                        <v-col class="py-0">
                            <v-datetime-field v-model="education.enrollmentStartDate" label="지원기간 시작일" persistent-placeholder hide-details @input="setStatus"></v-datetime-field>
                        </v-col>
                        <v-col class="py-0">
                            <v-datetime-field v-model="education.enrollmentEndDate" label="지원기간 종료일" persistent-placeholder hide-details @input="setStatus"></v-datetime-field>
                        </v-col>
                        <v-col class="py-0">
                            <v-datetime-field v-model="education.educationStartDate" label="교육기간 시작일" persistent-placeholder hide-details></v-datetime-field>
                        </v-col>
                        <v-col class="py-0">
                            <v-datetime-field v-model="education.educationEndDate" label="교육기간 종료일" persistent-placeholder hide-details></v-datetime-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3">
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-6">
                <v-card-title class="subtitle-1 pb-0">첨부파일</v-card-title>
                <v-card-text>
                    <v-file-input v-model="education.upload" multiple label="첨부파일" persistent-placeholder hide-details small-chips />
                </v-card-text>
            </v-card>

            <v-card class="mt-6">
                <v-card-title class="subtitle-1">내용</v-card-title>
                <v-card-text>
                    <naver-smarteditor v-model="education.content" id="content" rows="10"></naver-smarteditor>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { ENROLLMENT_STATUS } from "@/assets/variables"

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

import { mdiTagPlus } from "@mdi/js";
import urlParser from "js-video-url-parser";
import YoutubeCategoriesView from "@/components/console/boards/youtube-categories-view.vue";
import ReportViewCategories from "@/components/console/boards/report-view-categories.vue";
import FormsViewCategories from "@/components/console/boards/forms-view-categories.vue";
export default {
    components: {
        VDateField,
        VDatetimeField,
        DaumPostcode,
        NaverSmarteditor,
        YoutubeCategoriesView,
        ReportViewCategories,
        FormsViewCategories,
    },
    data() {
        return {
            mdiTagPlus,
            ready: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            education:{
              title: "",
              capacity: "",
              content: "",
              upload: null,
              status: "",
              isClosed: false,

              enrollmentStartDate: "",
              enrollmentEndDate: "",

              educationStartDate: "",
              educationEndDate: "",
            }
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (this.$route.params._education) {
                    let { education } = await api.console.education.get({ _id: this.$route.params._education });
                    education = { ...education, 
                                    enrollmentStartDate: this.$dayjs(education.enrollmentStartDate).format("YYYY-MM-DD HH:mm:ss"),
                                    enrollmentEndDate: this.$dayjs(education.enrollmentEndDate).format("YYYY-MM-DD HH:mm:ss"),
                                    educationStartDate: this.$dayjs(education.educationStartDate).format("YYYY-MM-DD HH:mm:ss"),
                                    educationEndDate: this.$dayjs(education.educationEndDate).format("YYYY-MM-DD HH:mm:ss")
                                };
                    if(education.upload){
                        education.upload= await Promise.all(education.upload.map(async(file)=> await api.getResource(file, true)));
                    }
                    
                    this.education = education;

                    this.setStatus();
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                if(!this.validation()) return;
                let { upload, ...education } = this.education;
                let { put, post } = api.console.education;
                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                ({ education } = education._id ? await put(education) : await post(education));
                // //////////////////////////////////////////////////////////////
                // // 2. 리소스 저장
                // //////////////////////////////////////////////////////////////
                let uploadArr = []; 
                if (upload) {
                    // 기존 파일 삭제
                    await api.console.education.upload.delete(education._id);

                    await Promise.all(upload.map(async(file)=>{
                        //파일 저장
                        return api.console.education.upload.post(education._id, file);
                    })).then(async(result)=>{
                        //파일 경로 저장
                        result.forEach((item)=>{ uploadArr.push(item.upload) });

                        await api.console.education.upload.put({_id: education._id, uploadArr});
                    }).catch((error)=>{
                        console.error(error);
                        alert(error.response ? error.response.data.message : error.message);
                    })
                }
                alert(this.education._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },
        setStatus(){
          if(this.isDateEntered()){ this.education.status = this.compareDate()};

          if(this.education.isClosed) this.education.status = ENROLLMENT_STATUS.CLOSED.text;
        },
        isDateEntered() {
            return this.education.enrollmentStartDate && this.education.enrollmentEndDate;
        },
        compareDate() {
            let { enrollmentStartDate: start,  enrollmentEndDate: end } = this.education;
            const now = this.$dayjs().valueOf();
            start = this.$dayjs(start).valueOf();
            end = this.$dayjs(end).valueOf();

            if(now < start){
              return ENROLLMENT_STATUS.PLANNED.text;
            };

            if(start <= now && now <= end){              
              return ENROLLMENT_STATUS.IN_PROGRESS.text;
            };
            
            if(end < now){              
              return ENROLLMENT_STATUS.CLOSED.text;
            };
        },
        validation(){
            const education = this.education;
            if(!education.title) throw new Error("교육명을 입력해주세요");
            if(!education.capacity) throw new Error("모집인원을 입력해주세요");
            if(!education.enrollmentStartDate) throw new Error("지원기간 시작일을 입력해주세요");
            if(!education.enrollmentEndDate) throw new Error("지원기간 종료일을 입력해주세요");
            if(!education.educationStartDate) throw new Error("교육기간 시작일을 입력해주세요");
            if(!education.educationEndDate) throw new Error("교육기간 종료일을 입력해주세요");
            if(!education.content) throw new Error("내용을 입력해주세요");

            return true
        }
    },
    watch: {
        // // 지원기간 종료 시 접수 마감 처리
        // "education.status"() {
        //     this.education.isClosed = false;

        //     if(this.education.status === ENROLLMENT_STATUS.CLOSED.text){
        //         this.education.isClosed = true;
        //     }
        // },
        // 마감(switch) 시 접수 마감 처리
        // "education.isClosed"(){
        //     this.setStatus();
        //     if(this.education.isClosed){
        //         this.education.status = ENROLLMENT_STATUS.CLOSED.text;
        //     }
        // }
    }
};
</script>
